import './App.css';
import Allcomponents from "./components/All-components";

function App() {
  return (
    <div className="App">
      <Allcomponents />
    </div>
  );
}

export default App;
