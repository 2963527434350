import React from "react";

function Footer() {
      var year = new Date().getFullYear();

      return (
            <footer>
                  <p>Vipin khandelwal All Copyright &copy; {year}</p>
            </footer>
      );
}

export default Footer;