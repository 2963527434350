import React from "react";
import logo from "./Vipinlogo.png"
function Header() {
      return (
            <header>
                  <img src={logo} class="logo" width="50" height="50" alt="" />
                  <h1 >Vipin khandelwal</h1>
            </header>
      );
}

export default Header;